import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Card, CardContent } from "@mui/material";
import { w3cwebsocket as W3CWebSocket } from "websocket";

const useStyles = makeStyles({
  container: {
    marginTop: "50px",
  },
  card: {
    marginBottom: "20px",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  link: {
    textAlign: "left",
    flexGrow: 1,
    marginRight: "8px", // Adjust spacing here
  },
  sentiment: {
    textAlign: "right",
  },
});


function Users() {
  const classes = useStyles();
  const [urlData, setUrlData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://safesites.cttbs.org/urllist");
        const sortedData = response.data.sort((a, b) => {
          return a.url.localeCompare(b.url);
        });
        setUrlData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Fetch data when the component mounts

    const client = new W3CWebSocket("wss://safesites.cttbs.org/urllist");

    client.onopen = () => {
      console.log("WebSocket Client Connected");

      const fetchUrlData = async () => {
        try {
          const response = await axios.get("https://safesites.cttbs.org/urllist");
          console.log("response : ", response);
          const sortedData = response.data.sort((a, b) => {
            return a.url.localeCompare(b.url);
          });

          setUrlData(sortedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    };

    client.onmessage = (message) => {
      console.log("Received data from server:", message.data);
      const data = JSON.parse(message.data);
      setUrlData(data);
    };

    client.onerror = (error) => {
      console.error("WebSocket connection error: ", error);
    };

    const intervalId = setInterval(fetchData, 30000); // Refresh every 1 minute

    return () => {
      clearInterval(intervalId);
      client.close();
    };
  }, []);

  return (
    <Container className={classes.container}>
      {urlData.map((item) => (
        <Card className={classes.card} key={item.id}>
          <CardContent className={classes.cardContent}>
            <div className={classes.content}>
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                <Typography variant="subtitle2">{item.url}</Typography>
              </a>
              <Typography variant="subtitle2" className={classes.sentiment}>{item.type}</Typography>
            </div>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
}

export default Users;