import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import topBanner from './img/SafeSitesLogo2.png';

import TableToDisplay from './components/table.js'

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const THEME = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },

  palette: {
    buttonColor: createColor('#666666'),
    checkStakeButtonColor: createColor('#000000'),
    initialConnectButtonColor: createColor('#FF0000'),
    successConnectButtonColor: createColor('#000000'),
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFFFFF', // Set the background color to black
          color: '#000000', // Set the text color to white
        },
      },
    },
  },
});


function App() {
  return (
    <>

        <div style={{marginTop: 10}} align="center">
          <Grid container alignItems="center" justifyContent="space-evenly" justify="center" direction="column" spacing={0}>
            <Grid item xs={3} md={3}>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                <p align="center">
                  <img src={topBanner} width="100%" alt="logo" />
                </p>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div style={{marginTop: 10}} align="center">
          <Typography variant="subtitle2" gutterBottom>
            A List of Safe URLS with Sentiment Analysis as a way to detect if one of them is under attack or compromised.<br />
            If you find this page is useful, you can send USDC or USDT on Arbitrum or Polygon chains as an appreciation to<br />
            0x8CC964565f7Ee8E987a471BC1B9F33f94691D15e
          </Typography>
        </div>

        <div>
          <br></br>
          <br></br>
        </div>

        <div style={{marginTop: 10}} align="center">
          <Grid container spacing={2}>
            <Grid xs={12} md={12}>
              <TableToDisplay />
            </Grid>

          </Grid>
        </div>

        <div>
          <br></br>
          <br></br>
        </div>

        <div style={{marginTop: 10}} align="center">
          <Typography variant="subtitle2" gutterBottom>
            A proud partner of cttbs.org <br />
            <a href="mailto:cttbs@gmail.com">Email</a> us with your ideas on what bullshit you will like to cut through, or what you can contribute to this movement!
          </Typography>
        </div>

        <div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

        </div>


    </>
  );
}

export default App;
